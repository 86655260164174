define('ember-api-store/services/store', ['exports', 'ember', 'ember-api-store/mixins/serializable', 'ember-api-store/models/error', 'ember-api-store/utils/normalize', 'ember-api-store/utils/apply-headers', 'ember-api-store/utils/fetch', 'ember-api-store/utils/url-options'], function (exports, _ember, _emberApiStoreMixinsSerializable, _emberApiStoreModelsError, _emberApiStoreUtilsNormalize, _emberApiStoreUtilsApplyHeaders, _emberApiStoreUtilsFetch, _emberApiStoreUtilsUrlOptions) {
  'use strict';

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  var getOwner = _ember['default'].getOwner;
  var defaultMetaKeys = ['actionLinks', 'createDefaults', 'createTypes', 'filters', 'links', 'pagination', 'resourceType', 'sort', 'sortLinks', 'type'];
  exports.defaultMetaKeys = defaultMetaKeys;

  var neverMissing = ['error'];

  exports.neverMissing = neverMissing;

  var Store = _ember['default'].Service.extend({
    defaultTimeout: 30000,
    defaultPageSize: 1000,
    baseUrl: '/v1',
    metaKeys: null,
    neverMissing: null,
    replaceActions: 'actionLinks',
    dropKeys: null,
    shoeboxName: 'ember-api-store',
    headers: null,

    arrayProxyClass: _ember['default'].ArrayProxy,
    arrayProxyKey: 'content',
    arrayProxyOptions: null,

    // true: automatically remove from store after a record.delete() succeeds.  You might want to disable this if your API has a multi-step deleted vs purged state.
    removeAfterDelete: true,

    fastboot: _ember['default'].computed(function () {
      return _ember['default'].getOwner(this).lookup('service:fastboot');
    }),

    init: function init() {
      this._super();

      if (!this.get('metaKeys')) {
        this.set('metaKeys', defaultMetaKeys.slice());
      }

      if (!this.get('neverMissing')) {
        this.set('neverMissing', neverMissing.slice());
      }

      this._state = {
        cache: null,
        cacheMap: null,
        classCache: null,
        foundAll: null,
        findQueue: null,
        missingMap: null
      };

      var fastboot = this.get('fastboot');
      if (fastboot) {
        var _name = this.get('shoeboxName');
        if (fastboot.get('isFastBoot')) {
          fastboot.get('shoebox').put(_name, this._state);
        } else {
          var box = fastboot.get('shoebox').retrieve(_name);
          if (box) {
            this._state = box;
          }
        }
      }

      this.reset();
    },

    // All the saved state goes in here
    _state: null,

    // You can observe this to tell when a reset() happens
    generation: 0,

    // Synchronously get record from local cache by [type] and [id].
    // Returns undefined if the record is not in cache, does not talk to API.
    getById: function getById(type, id) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._groupMap(type);
      return group[id];
    },

    // Synchronously returns whether record for [type] and [id] is in the local cache.
    hasRecordFor: function hasRecordFor(type, id) {
      return !!this.getById(type, id);
    },

    // Synchronously returns whether this exact record object is in the local cache
    hasRecord: function hasRecord(obj) {
      if (!obj) {
        return false;
      }

      var type = (0, _emberApiStoreUtilsNormalize.normalizeType)(obj.get('type'));
      var group = this._groupMap(type);
      return group[obj.get('id')] === obj;
    },

    isCacheable: function isCacheable(opt) {
      return !opt || opt.depaginate && !opt.filter && !opt.include;
    },

    // Asynchronous, returns promise.
    // find(type[,null, opt]): Query API for all records of [type]
    // find(type,id[,opt]): Query API for record [id] of [type]
    // opt:
    //  filter: Filter by fields, e.g. {field: value, anotherField: anotherValue} (default: none)
    //  include: Include link information, e.g. ['link', 'anotherLink'] (default: none)
    //  forceReload: Ask the server even if the type+id is already in cache. (default: false)
    //  limit: Number of reqords to return per page (default: 1000)
    //  depaginate: If the response is paginated, retrieve all the pages. (default: true)
    //  headers: Headers to send in the request (default: none).  Also includes ones specified in the model constructor.
    //  url: Use this specific URL instead of looking up the URL for the type/id.  This should only be used for bootstraping schemas on startup.
    find: function find(type, id, opt) {
      var _this = this;

      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      opt = opt || {};
      opt.depaginate = opt.depaginate !== false;

      if (!id && !opt.limit) {
        opt.limit = this.defaultPageSize;
      }

      if (!type) {
        return _ember['default'].RSVP.reject(new _emberApiStoreModelsError['default']('type not specified'));
      }

      // If this is a request for all of the items of [type], then we'll remember that and not ask again for a subsequent request
      var isCacheable = this.isCacheable(opt);
      opt.isForAll = !id && isCacheable;

      // See if we already have this resource, unless forceReload is on.
      if (opt.forceReload !== true) {
        if (opt.isForAll && this._state.foundAll[type]) {
          return _ember['default'].RSVP.resolve(this.all(type), 'Cached find all ' + type);
        } else if (isCacheable && id) {
          var existing = this.getById(type, id);
          if (existing) {
            return _ember['default'].RSVP.resolve(existing, 'Cached find ' + type + ':' + id);
          }
        }
      }

      // If URL is explicitly given, go straight to making the request.  Do not pass go, do not collect $200.
      // This is used for bootstraping to load the schema initially, and shouldn't be used for much else.
      if (opt.url) {
        return this._findWithUrl(opt.url, type, opt);
      } else {
        // Otherwise lookup the schema for the type and generate the URL based on it.
        return this.find('schema', type, { url: 'schemas/' + encodeURIComponent(type) }).then(function (schema) {
          var url = schema.linkFor('collection') + (id ? '/' + encodeURIComponent(id) : '');
          return _this._findWithUrl(url, type, opt);
        });
      }
    },

    // Returns a 'live' array of all records of [type] in the cache.
    all: function all(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._group(type);
      return this._createArrayProxy(group);
    },

    haveAll: function haveAll(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      return this._state.foundAll[type];
    },

    // find(type) && return all(type)
    findAll: function findAll(type, opt) {
      var _this2 = this;

      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      opt = opt || {};

      if (this.haveAll(type) && opt.forceReload !== true) {
        return _ember['default'].RSVP.resolve(this.all(type), 'All ' + type + ' already cached');
      } else {
        return this.find(type, undefined, opt).then(function () {
          return _this2.all(type);
        });
      }
    },

    normalizeUrl: function normalizeUrl(url) {
      var includingAbsolute = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var origin = window.location.origin;

      // Make absolute URLs to ourselves root-relative
      if (includingAbsolute && url.indexOf(origin) === 0) {
        url = url.substr(origin.length);
      }

      // Make relative URLs root-relative
      if (!url.match(/^https?:/) && url.indexOf('/') !== 0) {
        url = this.get('baseUrl').replace(/\/\+$/, '') + '/' + url;
      }

      return url;
    },

    // Makes an AJAX request and returns a promise that resolves to an object
    // This is separate from request() so it can be mocked for tests, or if you just want a basic AJAX request.
    rawRequest: function rawRequest(opt) {
      opt.url = this.normalizeUrl(opt.url);
      opt.headers = this._headers(opt.headers);
      opt.processData = false;
      if (typeof opt.dataType === 'undefined') {
        opt.dataType = 'text'; // Don't let jQuery JSON parse
      }

      if (opt.timeout !== null && !opt.timeout) {
        opt.timeout = this.defaultTimeout;
      }

      if (opt.data) {
        if (!opt.contentType) {
          opt.contentType = 'application/json';
        }

        if (_emberApiStoreMixinsSerializable['default'].detect(opt.data)) {
          opt.data = JSON.stringify(opt.data.serialize());
        } else if (typeof opt.data === 'object') {
          opt.data = JSON.stringify(opt.data);
        }
      }

      return (0, _emberApiStoreUtilsFetch['default'])(opt.url, opt);
    },

    // Makes an AJAX request that resolves to a resource model
    request: function request(opt) {
      var _this3 = this;

      opt.url = this.normalizeUrl(opt.url);
      opt.depaginate = opt.depaginate !== false;

      if (this.mungeRequest) {
        opt = this.mungeRequest(opt);
      }

      return this.rawRequest(opt).then(function (xhr) {
        return _this3._requestSuccess(xhr, opt);
      })['catch'](function (xhr) {
        return _this3._requestFailed(xhr, opt);
      });
    },

    // Forget about all the resources that hae been previously remembered.
    reset: function reset() {
      var cache = this._state.cache;
      if (cache) {
        Object.keys(cache).forEach(function (key) {
          if (cache[key] && cache[key].clear) {
            cache[key].clear();
          }
        });
      } else {
        this._state.cache = {};
      }

      var foundAll = this._state.foundAll;
      if (foundAll) {
        Object.keys(foundAll).forEach(function (key) {
          foundAll[key] = false;
        });
      } else {
        this._state.foundAll = {};
      }

      this._state.cacheMap = {};
      this._state.findQueue = {};
      this._state.classCache = [];
      this._state.missingMap = {};
      this.incrementProperty('generation');
    },

    resetType: function resetType(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._group(type);
      this._state.foundAll[type] = false;
      this._state.cacheMap[type] = {};
      group.clear();
    },

    // ---------
    // Below here be dragons
    // ---------
    _createArrayProxy: function _createArrayProxy(content) {
      var data = _defineProperty({}, this.arrayProxyKey, content);

      var opt = this.get('arrayProxyOptions') || {};
      Object.keys(opt).forEach(function (key) {
        data[key] = opt[key];
      });

      return this.arrayProxyClass.create(data);
    },

    _headers: function _headers(perRequest) {
      var out = {
        'accept': 'application/json',
        'content-type': 'application/json'
      };

      (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(this.get('headers'), out);
      (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(perRequest, out);
      return out;
    },

    _findWithUrl: function _findWithUrl(url, type, opt) {
      var _this4 = this;

      var queue = this._state.findQueue;
      var cls = getOwner(this).lookup('model:' + type);
      url = (0, _emberApiStoreUtilsUrlOptions.urlOptions)(url, opt, cls);

      // Collect Headers
      var newHeaders = {};
      if (cls && cls.constructor.headers) {
        (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(cls.constructor.headers, newHeaders, true);
      }
      (0, _emberApiStoreUtilsApplyHeaders.applyHeaders)(opt.headers, newHeaders, true);
      // End: Collect headers

      var later;
      var queueKey = JSON.stringify(newHeaders) + url;

      // check to see if the request is in the findQueue
      if (queue[queueKey]) {
        // get the filterd promise object
        var filteredPromise = queue[queueKey];
        var defer = _ember['default'].RSVP.defer();
        filteredPromise.push(defer);
        later = defer.promise;
      } else {
        // request is not in the findQueue

        opt.url = url;
        opt.headers = newHeaders;

        later = this.request(opt).then(function (result) {
          if (opt.isForAll) {
            _this4._state.foundAll[type] = true;
          }

          _this4._finishFind(queueKey, result, 'resolve');
          return result;
        }, function (reason) {
          _this4._finishFind(queueKey, reason, 'reject');
          return _ember['default'].RSVP.reject(reason);
        });

        // set the queue array to empty indicating we've had 1 promise already
        queue[queueKey] = [];
      }

      return later;
    },

    _finishFind: function _finishFind(key, result, type) {
      var queue = this._state.findQueue;
      var promises = queue[key];

      if (promises) {
        while (promises.length) {
          if (type === 'resolve') {
            promises.pop().resolve(result);
          } else if (type === 'reject') {
            promises.pop().reject(result);
          }
        }
      }

      delete queue[key];
    },

    _requestSuccess: function _requestSuccess(xhr, opt) {
      var _this5 = this;

      if (xhr.status === 204) {
        return;
      }

      if (xhr.body && typeof xhr.body === 'object') {
        var _ret = (function () {
          _ember['default'].beginPropertyChanges();
          var response = _this5._typeify(xhr.body);
          delete xhr.body;
          Object.defineProperty(response, 'xhr', { value: xhr, configurable: true });
          _ember['default'].endPropertyChanges();

          // Note which keys were included in each object
          if (opt.include && opt.include.length && response.forEach) {
            response.forEach(function (obj) {
              obj.includedKeys = obj.includedKeys || [];
              obj.includedKeys.pushObjects(opt.include.slice());
              obj.includedKeys = obj.includedKeys.uniq();
            });
          }

          // Depaginate
          if (opt.depaginate && typeof response.depaginate === 'function') {
            return {
              v: response.depaginate().then(function () {
                return response;
              })['catch'](function (xhr) {
                return _this5._requestFailed(xhr, opt);
              })
            };
          } else {
            return {
              v: response
            };
          }
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        return xhr.body;
      }
    },

    _requestFailed: function _requestFailed(xhr, opt) {
      var body;

      if (xhr.err) {
        if (xhr.err === 'timeout') {
          body = {
            code: 'Timeout',
            status: xhr.status,
            message: 'API request timeout (' + opt.timeout / 1000 + ' sec)',
            detail: (opt.method || 'GET') + ' ' + opt.url
          };
        } else {
          body = { status: xhr.status, message: xhr.err };
        }

        return finish(body);
      } else if (xhr.body && typeof xhr.body === 'object') {
        _ember['default'].beginPropertyChanges();
        var out = finish(this._typeify(xhr.body));
        _ember['default'].endPropertyChanges();
        return out;
      } else {
        body = { status: xhr.status, message: xhr.body };
        return finish(body);
      }

      function finish(body) {
        if (!_emberApiStoreModelsError['default'].detectInstance(body)) {
          body = _emberApiStoreModelsError['default'].create(body);
        }

        delete xhr.body;
        Object.defineProperty(body, 'xhr', { value: xhr, configurable: true });
        return _ember['default'].RSVP.reject(body);
      }
    },

    // Get the cache array group for [type]
    _group: function _group(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var cache = this._state.cache;
      var group = cache[type];
      if (!group) {
        group = [];
        cache[type] = group;
      }

      return group;
    },

    // Get the cache map group for [type]
    _groupMap: function _groupMap(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var cache = this._state.cacheMap;
      var group = cache[type];
      if (!group) {
        group = {};
        cache[type] = group;
      }

      return group;
    },

    // Add a record instance of [type] to cache
    _add: function _add(type, obj) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._group(type);
      var groupMap = this._groupMap(type);
      group.pushObject(obj);
      groupMap[obj.id] = obj;

      if (obj.wasAdded && typeof obj.wasAdded === 'function') {
        obj.wasAdded();
      }
    },

    // Add a lot of instances of the same type quickly.
    //   - There must be a model for the type already defined.
    //   - Instances cannot contain any nested other types (e.g. include or subtypes),
    //     (they will not be deserialzed into their correct type.)
    //   - wasAdded hooks are not called
    // Basically this is just for loading schemas faster.
    _bulkAdd: function _bulkAdd(type, pojos) {
      var _this6 = this;

      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._group(type);
      var groupMap = this._groupMap(type);
      var cls = getOwner(this).lookup('model:' + type);
      group.pushObjects(pojos.map(function (input) {

        // actions is very unhappy property name for Ember...
        if (_this6.replaceActions && typeof input.actions !== 'undefined') {
          input[_this6.replaceActions] = input.actions;
          delete input.actions;
        }

        // Schemas are special
        if (type === 'schema') {
          input._id = input.id;
          input.id = (0, _emberApiStoreUtilsNormalize.normalizeType)(input.id);
        }

        input.store = _this6;
        var obj = cls.constructor.create(input);
        groupMap[obj.id] = obj;
        return obj;
      }));
    },

    // Remove a record of [type] from cache, given the id or the record instance.
    _remove: function _remove(type, obj) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var group = this._group(type);
      var groupMap = this._groupMap(type);
      group.removeObject(obj);
      delete groupMap[obj.id];

      if (obj.wasRemoved && typeof obj.wasRemoved === 'function') {
        obj.wasRemoved();
      }
    },

    // Turn a POJO into a Model: {updateStore: true}
    _typeify: function _typeify(input) {
      var _this7 = this;

      var opt = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

      if (!input || typeof input !== 'object') {
        // Simple values can just be returned
        return input;
      }

      if (!opt) {
        opt = { applyDefaults: false };
      }

      var type = _ember['default'].get(input, 'type');
      if (_ember['default'].isArray(input)) {
        // Recurse over arrays
        return input.map(function (x) {
          return _this7._typeify(x, opt);
        });
      } else if (!type) {
        // If it doesn't have a type then there's no sub-fields to typeify
        return input;
      }

      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      if (type === 'collection') {
        return this.createCollection(input, opt);
      } else if (!type) {
        return input;
      }

      var rec = this.createRecord(input, opt);
      if (!input.id || opt.updateStore === false) {
        return rec;
      }

      // This must be after createRecord so that mangleIn() can change the baseType
      var baseType = rec.get('baseType');
      if (baseType) {
        baseType = (0, _emberApiStoreUtilsNormalize.normalizeType)(baseType);

        // Only use baseType if it's different from type
        if (baseType === type) {
          baseType = null;
        }
      }

      var out = rec;
      var cacheEntry = this.getById(type, rec.id);
      var baseCacheEntry = undefined;
      if (baseType) {
        baseCacheEntry = this.getById(baseType, rec.id);
      }

      if (cacheEntry) {
        cacheEntry.replaceWith(rec);
        out = cacheEntry;
      } else {
        this._add(type, rec);
        if (baseType) {
          this._add(baseType, rec);
        }
      }

      if (type && !this.neverMissing.includes(type)) {
        _ember['default'].run.next(this, '_notifyMissing', type, rec.id);

        if (baseType && !this.neverMissing.includes(type)) {
          _ember['default'].run.next(this, '_notifyMissing', baseType, rec.id);
        }
      }

      return out;
    },

    // Create a collection: {key: 'data'}
    createCollection: function createCollection(input, opt) {
      var _this8 = this;

      _ember['default'].beginPropertyChanges();
      var key = opt && opt.key ? opt.key : 'data';
      var cls = getOwner(this).lookup('model:collection');
      var content = input[key].map(function (x) {
        return _this8._typeify(x, opt);
      });
      var output = cls.constructor.create({ content: content });

      Object.defineProperty(output, 'store', { value: this, configurable: true });

      output.setProperties(_ember['default'].getProperties(input, this.get('metaKeys')));
      _ember['default'].endPropertyChanges();
      return output;
    },

    getClassFor: function getClassFor(type) {
      var cls = this._state.classCache[type];
      if (cls) {
        return cls;
      }

      var owner = getOwner(this);
      if (type) {
        cls = owner.lookup('model:' + type);
      }

      if (!cls) {
        cls = owner.lookup('model:resource');
      }

      this._state.classCache[type] = cls;
      return cls;
    },

    // Create a record: {applyDefaults: false}
    createRecord: function createRecord(data, opt) {
      opt = opt || {};
      var type = (0, _emberApiStoreUtilsNormalize.normalizeType)(_ember['default'].get(opt, 'type') || _ember['default'].get(data, 'type') || '');

      var cls = undefined;
      if (type) {
        cls = this.getClassFor(type);
      }

      var schema = this.getById('schema', type);
      var input = data;
      if (opt.applyDefaults !== false && schema) {
        input = schema.getCreateDefaults(data);
      }

      // actions is very unhappy property name for Ember...
      if (this.replaceActions && typeof input.actions !== 'undefined') {
        input[this.replaceActions] = input.actions;
        delete input.actions;
      }

      var cons = cls.constructor;
      if (cons.mangleIn && typeof cons.mangleIn === 'function') {
        input = cons.mangleIn(input, this);
      }

      if (schema) {
        var fields = schema.get('typeifyFields');
        for (var i = fields.length - 1; i >= 0; i--) {
          var k = fields[i];
          if (input[k]) {
            input[k] = this._typeify(input[k], opt);
          }
        }
      }

      var output = cons.create(input);

      Object.defineProperty(output, 'store', { value: this, configurable: true });
      return output;
    },

    // Handle missing records in denormalized arrays
    // Get the cache map missing for [type]
    _missingMap: function _missingMap(type) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var cache = this._state.missingMap;
      var group = cache[type];
      if (!group) {
        group = {};
        cache[type] = group;
      }

      return group;
    },

    _missing: function _missing(type, id, dependent, key) {
      type = (0, _emberApiStoreUtilsNormalize.normalizeType)(type);
      var missingMap = this._missingMap(type);
      var entries = missingMap[id];
      if (!entries) {
        entries = [];
        missingMap[id] = entries;
      }

      //console.log('Missing', type, id, 'for', key, 'in', dependent);
      entries.push({ o: dependent, k: key });
    },

    _notifyMissing: function _notifyMissing(type, id) {
      var missingMap = this._missingMap(type);
      var entries = missingMap[id];
      //console.log('Notify missing',type,id, entries);
      if (entries) {
        entries.forEach(function (entry) {
          //console.log('Recomputing', entry.k, 'for', type, id, 'in', entry.o);
          entry.o.notifyPropertyChange(entry.k);
        });

        entries.clear();
      }
    }
  });

  exports['default'] = Store;
});