define('ember-api-store/mixins/type', ['exports', 'ember', 'ember-api-store/mixins/serializable', 'ember-api-store/utils/normalize', 'ember-api-store/utils/apply-headers', 'ember-api-store/utils/url-options'], function (exports, _ember, _emberApiStoreMixinsSerializable, _emberApiStoreUtilsNormalize, _emberApiStoreUtilsApplyHeaders, _emberApiStoreUtilsUrlOptions) {
  'use strict';

  var Type = _ember['default'].Mixin.create(_emberApiStoreMixinsSerializable['default'], {
    id: null,
    type: null,
    links: null,

    toString: function toString() {
      return '(generic type mixin)';
    },

    // unionArrays=true will append the new values to the existing ones instead of overwriting.
    merge: function merge(newData, unionArrays) {
      var self = this;
      newData.eachKeys(function (v, k) {
        if (newData.hasOwnProperty(k)) {
          var curVal = self.get(k);
          if (unionArrays && _ember['default'].isArray(curVal) && _ember['default'].isArray(v)) {
            curVal.addObjects(v);
          } else {
            self.set(k, v);
          }
        }
      });

      return self;
    },

    replaceWith: function replaceWith(newData) {
      var self = this;
      // Add/replace values that are in newData
      newData.eachKeys(function (v, k) {
        self.set(k, v);
      });

      // Remove values that are in current but not new.
      var newKeys = newData.allKeys();
      this.eachKeys(function (v, k) {
        // If the key is a valid link name and
        if (newKeys.indexOf(k) === -1 && !this.hasLink(k)) {
          self.set(k, undefined);
        }
      });

      return self;
    },

    clone: function clone() {
      var store = this.get('store');
      var output = store.createRecord(JSON.parse(JSON.stringify(this.serialize())), { updateStore: false });
      //output.set('store', this.get('store'));
      return output;
    },

    linkFor: function linkFor(name) {
      var url = this.get('links.' + name);
      return url;
    },

    pageFor: function pageFor(name) {
      return this.get('pagination.' + name);
    },

    hasLink: function hasLink(name) {
      return !!this.linkFor(name);
    },

    headers: null,
    request: function request(opt) {
      if (!opt.headers) {
        opt.headers = {};
      }

      (0, _emberApiStoreUtilsApplyHeaders.copyHeaders)(this.constructor.headers, opt.headers);
      (0, _emberApiStoreUtilsApplyHeaders.copyHeaders)(this.get('headers'), opt.headers);

      return this.get('store').request(opt);
    },

    followPagination: function followPagination(name) {
      var url = this.pageFor(name);

      if (!url) {
        throw new Error('Unknown link');
      }

      return this.request({
        method: 'GET',
        url: url,
        depaginate: false
      });
    },

    followLink: function followLink(name, opt) {
      var url = this.linkFor(name);
      opt = opt || {};

      if (!url) {
        throw new Error('Unknown link');
      }

      url = (0, _emberApiStoreUtilsUrlOptions.urlOptions)(url, opt);

      return this.request({
        method: 'GET',
        url: url
      });
    },

    importLink: function importLink(name, opt) {
      var self = this;
      opt = opt || {};

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        self.followLink(name, opt).then(function (data) {
          self.set(opt.as || name, data);
          resolve(self);
        })['catch'](function (err) {
          reject(err);
        });
      }, 'Import Link: ' + name);
    },

    hasAction: function hasAction(name) {
      var url = this.get('actionLinks.' + name);
      return !!url;
    },

    computedHasAction: function computedHasAction(name) {
      return _ember['default'].computed('actionLinks.' + name, function () {
        return this.hasAction(name);
      });
    },

    doAction: function doAction(name, data, opt) {
      var url = this.get('actionLinks.' + name);
      if (!url) {
        return _ember['default'].RSVP.reject(new Error('Unknown action: ' + name));
      }

      opt = opt || {};
      opt.method = 'POST';
      opt.url = opt.url || url;
      if (data) {
        opt.data = data;
      }

      // Note: The response object may or may not be this same object, depending on what the action returns.
      return this.request(opt);
    },

    save: function save(opt) {
      var self = this;
      var store = this.get('store');
      opt = opt || {};

      var id = this.get('id');
      var type = (0, _emberApiStoreUtilsNormalize.normalizeType)(this.get('type'));
      if (id) {
        // Update
        opt.method = opt.method || 'PUT';
        opt.url = opt.url || this.linkFor('self');
      } else {
        // Create
        if (!type) {
          return _ember['default'].RSVP.reject(new Error('Cannot create record without a type'));
        }

        opt.method = opt.method || 'POST';
        opt.url = opt.url || type;
      }

      var json = this.serialize();
      // Don't send included link maps/arrays
      Object.keys(json.links || {}).forEach(function (k) {
        delete json[k];
      });
      delete json['links'];
      delete json['actions'];
      delete json['actionLinks'];

      if (typeof opt.data === 'undefined') {
        opt.data = json;
      }

      return this.request(opt).then(function (newData) {
        if (!newData || !Type.detect(newData)) {
          return newData;
        }

        var newId = newData.get('id');
        var newType = (0, _emberApiStoreUtilsNormalize.normalizeType)(newData.get('type'));
        if (!id && newId && type === newType) {
          _ember['default'].beginPropertyChanges();

          // A new record was created.  Typeify will have put it into the store,
          // but it's not the same instance as this object.  So we need to fix that.
          self.merge(newData);
          var existing = store.getById(type, newId);
          if (existing) {
            store._remove(type, existing);
          }
          store._add(type, self);

          // And also for the base type
          var baseType = self.get('baseType');
          if (baseType) {
            baseType = (0, _emberApiStoreUtilsNormalize.normalizeType)(baseType);
            if (baseType !== type) {
              existing = store.getById(baseType, newId);
              if (existing) {
                store._remove(baseType, existing);
              }
              store._add(baseType, self);
            }
          }

          _ember['default'].endPropertyChanges();
        }

        return self;
      });
    },

    'delete': function _delete(opt) {
      var self = this;
      var store = this.get('store');
      var type = this.get('type');

      opt = opt || {};
      opt.method = 'DELETE';
      opt.url = opt.url || this.linkFor('self');

      return this.request(opt).then(function (newData) {
        if (store.get('removeAfterDelete')) {
          store._remove(type, self);
        }
        return newData;
      });
    },

    reload: function reload(opt) {
      if (!this.hasLink('self')) {
        return _ember['default'].RSVP.reject('Resource has no self link');
      }

      var url = this.linkFor('self');
      if (this.constructor && this.constructor.alwaysInclude) {
        this.constructor.alwaysInclude.forEach(function (key) {
          url += (url.indexOf('?') >= 0 ? '&' : '?') + 'include=' + encodeURIComponent(key);
        });
      }

      opt = opt || {};
      if (typeof opt.method === 'undefined') {
        opt.method = 'GET';
      }

      if (typeof opt.url === 'undefined') {
        opt.url = url;
      }

      var self = this;
      return this.request(opt).then(function () /*newData*/{
        return self;
      });
    },

    isInStore: function isInStore() {
      var store = this.get('store');
      return store && this.get('id') && this.get('type') && store.hasRecord(this);
    }
  });

  exports['default'] = Type;
});