define('lacsso/mixins/sticky-table-header', ['exports', 'ember', 'lacsso/mixins/throttled-resize'], function (exports, _ember, _lacssoMixinsThrottledResize) {
  'use strict';

  var tableProps = {
    actionsHeight: '60px',
    fixedHeaderHeight: '40px'
  };

  exports['default'] = _ember['default'].Mixin.create(_lacssoMixinsThrottledResize['default'], {
    bulkActions: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $offset = _ember['default'].$(this.element).find('thead tr').offset().top;
      this.buildTableWidths();

      if (this.get('bulkActions')) {
        _ember['default'].$(this.element).find('thead .fixed-header-actions, thead .fixed-header').css('width', _ember['default'].$(this.element).find('table').outerWidth());
      }
      _ember['default'].$(window).scroll(function () {
        _this.updateHeaders($offset);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].$(window).unbind('scroll');
    },

    onResize: function onResize() {
      this.buildTableWidths();
    },

    buildTableWidths: function buildTableWidths() {
      var ths = _ember['default'].$(this.element).find('thead tr.fixed-header th');

      _ember['default'].$(this.element).find('thead tr.fixed-header-placeholder th').each(function (idx, th) {
        _ember['default'].$(ths[idx]).attr('width', _ember['default'].$(th).outerWidth());
      });

      if (this.get('bulkActions')) {
        _ember['default'].$(this.element).find('thead .fixed-header-actions, thead .fixed-header').css({
          'width': _ember['default'].$(this.element).find('table').width()
        });
      }
    },

    tearDownTableWidths: function tearDownTableWidths() {
      _ember['default'].$(this.element).find('thead tr.fixed-header th').each(function (idx, td) {
        _ember['default'].$(td).removeAttr('width');
      });
    },

    positionHeaders: function positionHeaders() {
      var $table = _ember['default'].$(this.element).find('table');
      var $actionRow = $table.find('thead .fixed-header-actions');
      var $fixedHeader = $table.find('thead tr.fixed-header');
      var bulkActions = this.get('bulkActions');

      if (bulkActions) {
        $actionRow.css({
          'position': 'fixed',
          'top': 0,
          'height': tableProps.actionsHeight
        });
      }
      $fixedHeader.css({
        'position': 'fixed',
        'top': bulkActions ? tableProps.actionsHeight : 0,
        'height': tableProps.fixedHeaderHeight
      });

      $table.css({
        'margin-top': parseInt(tableProps.actionsHeight, 10) + parseInt(tableProps.fixedHeaderHeight, 10) + 'px'
      });
    },

    removePositions: function removePositions() {
      var $table = _ember['default'].$(this.element).find('table');
      var $actionRow = $table.find('thead .fixed-header-actions');
      var $fixedHeader = $table.find('thead tr.fixed-header');

      if (this.get('bulkActions')) {
        $actionRow.css({
          'position': 'relative',
          'top': ''
        });
      }

      $fixedHeader.css({
        'position': '',
        'top': ''
      });
      $table.css({
        'margin-top': ''
      });
      this.buildTableWidths();
    },

    updateHeaders: function updateHeaders(offset) {
      var $windowScroll = _ember['default'].$(window).scrollTop();
      var $table = _ember['default'].$(this.element).find('table');
      var $floatingHeader = $table.find('thead tr.fixed-header');
      var $scrollTop = _ember['default'].$(window).scrollTop();
      var containerBottom = $table.height() + $table.offset().top;

      if ($windowScroll < containerBottom) {
        if ($scrollTop > offset) {
          this.buildTableWidths();
          this.positionHeaders();
        } else if ($scrollTop <= offset) {
          this.tearDownTableWidths();
          this.removePositions();
        }
      } else {
        if ($floatingHeader.css('position') === 'fixed') {
          this.tearDownTableWidths();
          this.removePositions();
        }
      }
    }
  });
});