define('lacsso/components/sortable-table', ['exports', 'ember', 'lacsso/templates/components/sortable-table', 'lacsso/mixins/sortable-base', 'lacsso/mixins/sticky-table-header', 'ember-cli-pagination/computed/paged-array', 'lacsso/utils/platform'], function (exports, _ember, _lacssoTemplatesComponentsSortableTable, _lacssoMixinsSortableBase, _lacssoMixinsStickyTableHeader, _emberCliPaginationComputedPagedArray, _lacssoUtilsPlatform) {
  'use strict';

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend(_lacssoMixinsSortableBase['default'], _lacssoMixinsStickyTableHeader['default'], {
    layout: _lacssoTemplatesComponentsSortableTable['default'],
    body: null,
    sorts: null,
    sortBy: null,
    headers: null,
    bulkActionsList: null,
    bulkActionCallee: null,
    perPage: 10,

    availableActions: null,
    selectedNodes: null,
    prevNode: null,
    searchText: null,
    page: 1,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('selectedNodes', []);

      _ember['default'].run.schedule('afterRender', function () {
        var tbody = _ember['default'].$(_this.element).find('table tbody');
        var self = _this; // need this context in click function and can't use arrow func there

        tbody.on('click', 'tr', function (e) {
          self.rowClick(e);
        });

        tbody.on('mousedown', 'tr', function (e) {
          if ((0, _lacssoUtilsPlatform.isRange)(e) || e.target.tagName === 'INPUT') {
            e.preventDefault();
          }
        });
      });
    },

    actions: {
      clearSearch: function clearSearch() {
        this.set('searchText', '');
      },

      executeBulkAction: function executeBulkAction(name) {
        this.get('bulkActionCallee')(name, this.get('selectedNodes'));
      },

      executeAction: function executeAction(action) {
        var node = this.get('selectedNodes')[0];
        node.send(action);
      }
    },

    // -----
    // Table content
    // Flow: body [-> sortableContent] -> arranged -> filtered -> pagedContent
    // -----
    sortableContent: _ember['default'].computed.alias('body'),
    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('filtered', { pageBinding: "page", perPageBinding: "perPage" }),

    filtered: _ember['default'].computed('arranged.[]', 'searchText', function () {
      var out = this.get('arranged').slice();
      var searchText = (this.get('searchText') || '').trim().toLowerCase();
      var searchFields = [];

      if (searchText.length) {
        this.get('headers').forEach(function (header) {
          var field = get(header, 'searchField') || get(header, 'name');
          if (field && typeof field === 'string' && field.length) {
            searchFields.push(field);
          }
        });

        out = out.filter(function (item) {
          for (var i = 0; i < searchFields.length; i++) {
            var val = (item.get(searchFields[i]) + '').toLowerCase();
            if (val && val.indexOf(searchText) >= 0) {
              return true;
            }
          }
        });
      }

      return out;
    }),

    pagedContentChanged: _ember['default'].observer('pagedContent.[]', function () {
      // Remove selected items not in the current content
      var content = this.get('pagedContent');
      var nodesToRemove = this.get('selectedNodes').filter(function (node) {
        return !content.includes(node);
      });

      this.toggleMulti([], nodesToRemove);
    }),

    indexFrom: _ember['default'].computed('page', 'perPage', function () {
      var current = this.get('page');
      var perPage = this.get('perPage');
      return Math.max(0, 1 + perPage * (current - 1));
    }),

    indexTo: _ember['default'].computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(this.get('filtered.length'), this.get('indexFrom') + this.get('perPage') - 1);
    }),

    pageCountContent: _ember['default'].computed('indexFrom', 'indexTo', 'pagedContent.totalPages', function () {
      var from = this.get('indexFrom');
      var to = this.get('indexTo');
      var count = this.get('filtered.length');
      var out = '';

      if (this.get('pagedContent.totalPages') <= 1) {
        out = count + ' Item' + (count === 1 ? '' : 's');
      } else {
        out = from + ' - ' + to + ' of ' + count;
      }

      return out;
    }),

    pageCountChanged: _ember['default'].observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      var from = this.get('indexFrom');
      var last = this.get('filtered.length');
      var perPage = this.get('perPage');

      if (this.get('page') > 1 && from > last) {
        var page = Math.ceil(last / perPage);
        this.set('page', page);
      }
    }),

    // ------
    // Clicking
    // ------
    rowClick: function rowClick(e) {
      var tagName = e.target.tagName;
      var content = this.get('pagedContent');
      var selection = this.get('selectedNodes');
      var isCheckbox = tagName === 'INPUT' || _ember['default'].$(e.target).hasClass('select-for-action');
      var nodeId = _ember['default'].$(e.currentTarget).find('input[type="checkbox"]').attr('nodeid');
      var node = content.findBy('id', nodeId);

      if (!node || tagName === 'A') {
        return;
      }

      var isSelected = selection.includes(node);

      var prevNode = this.get('prevNode');
      // PrevNode is only valid if it's in the current content
      if (!content.includes(prevNode)) {
        prevNode = null;
      }

      if (!prevNode) {
        prevNode = node;
      }

      if ((0, _lacssoUtilsPlatform.isMore)(e)) {
        this.toggleSingle(node);
      } else if ((0, _lacssoUtilsPlatform.isRange)(e)) {
        var from = content.indexOf(prevNode);
        var to = content.indexOf(node);
        var _ref = [Math.min(from, to), Math.max(from, to)];
        from = _ref[0];
        to = _ref[1];

        var toToggle = content.slice(from, to + 1);

        if (isSelected) {
          this.toggleMulti([], toToggle);
        } else {
          this.toggleMulti(toToggle, []);
        }
      } else if (isCheckbox) {
        this.toggleSingle(node);
      } else {
        this.toggleMulti([node], content);
      }

      this.set('prevNode', node);
    },

    isAll: _ember['default'].computed('selectedNodes.length', 'pagedContent.length', {
      get: function get() {
        return this.get('selectedNodes.length') === this.get('pagedContent.length');
      },

      set: function set(key, value) {
        var content = this.get('pagedContent');
        if (value) {
          this.toggleMulti(content, []);
          return true;
        } else {
          this.toggleMulti([], content);
          return false;
        }
      }
    }),

    toggleSingle: function toggleSingle(node) {
      var selectedNodes = this.get('selectedNodes');

      if (selectedNodes.includes(node)) {
        this.toggleMulti([], [node]);
      } else {
        this.toggleMulti([node], []);
      }
    },

    toggleMulti: function toggleMulti(nodesToAdd, nodesToRemove) {
      var selectedNodes = this.get('selectedNodes');

      if (nodesToRemove.length) {
        // removeObjects doesn't use ArrayProxy-safe looping
        if (typeof nodesToRemove.toArray === 'function') {
          nodesToRemove = nodesToRemove.toArray();
        }
        selectedNodes.removeObjects(nodesToRemove);
        nodesToRemove.forEach(function (node) {
          toggle(node, false);
        });
      }

      if (nodesToAdd.length) {
        selectedNodes.addObjects(nodesToAdd);
        nodesToAdd.forEach(function (node) {
          toggle(node, true);
        });
      }

      function toggle(node, on) {
        var id = get(node, 'id');
        if (id) {
          (function () {
            var input = _ember['default'].$('input[nodeid=' + id + ']');
            if (input && input.length) {
              _ember['default'].run.next(function () {
                input[0].checked = on;
              });
              _ember['default'].$(input).closest('tr').toggleClass('row-selected', on);
            }
          })();
        }
      }
    },

    actionsChanged: _ember['default'].observer('selectedNodes.@each.translatedAvailableActions', function () {
      var data = this.get('selectedNodes');
      var out = null;

      if (data.length > 1) {
        out = this.mergeBulkActions(data);
      } else if (data.length === 1) {
        out = this.mergeSingleActions(data[0]);
      }

      this.set('availableActions', out);
    }),

    mergeBulkActions: function mergeBulkActions(nodes) {
      var commonActions = _ember['default'].$().extend(true, [], this.get('bulkActionsList'));

      // loop over every selectedNode to find available actions
      nodes.forEach(function (item) {
        var actions = get(item, 'translatedAvailableActions').filter(function (action) {
          return action.enabled && action.bulkable;
        });

        commonActions.forEach(function (action) {
          if (!actions.findBy('action', action.action)) {
            set(action, 'disabled', true);
          }
        });
      });

      return commonActions;
    },

    mergeSingleActions: function mergeSingleActions(node) {
      var commonActions = _ember['default'].$().extend(true, [], this.get('bulkActionsList'));
      var localActions = [];

      // no others selected just push the availabe actions out
      localActions = get(node, 'translatedAvailableActions').filter(function (action) {
        return action.enabled;
      });

      // combine both arrays into a unique set
      commonActions = commonActions.concat(localActions).uniqBy('action');

      // find items that need to be disbaled
      commonActions.forEach(function (action) {
        if (!localActions.findBy('action', action.action)) {
          set(action, 'disabled', true);
        }
      });

      return commonActions;
    }
  });
});